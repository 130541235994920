import * as React from 'react'
import Layout from '../components/layout'

const SfFoundationPage = () => {
    return (
        <Layout title="SF Foundation">
            SF Foundation page
        </Layout>
    )
}

export default SfFoundationPage
